import React, { } from "react";

class About extends React.Component{
    render() {
    return (
        <section className="about_section layout_padding2" id="about_section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-13 offset-md-1">
                        <div className="detail-box pr-md-2">
                            <div className="heading_container">
                                <h2>
                                    About Us
                                </h2>
                            </div>
                            <p className="detail_p_mt">
                                At YesEd Academy, we are dedicated to empowering healthcare professionals like you to achieve excellence in the Occupational English Test (OET). Our institution was founded with a clear mission: to provide comprehensive, high-quality, and personalized training that equips you with the language skills and confidence needed to excel in the healthcare sector.
                            </p><br />
                            <a href="about.html" className="btn-box">
                                Read More
                            </a>
                        </div>
                    </div>
                    <div className="col-md-14 px-0">
                        <div className="img-box ">
                            <img src="images/about-img.jpg" className="box_img" alt="about img" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}
}
export default About;
