import React, { } from "react";
import { useEffect, useState } from "react";
//import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Carousel } from "react-bootstrap";
//import { MDBCarousel, MDBCarouselItem, } from 'mdb-react-ui-kit';




function Testimonials() {

  
  
  const [users, setUsers] = useState([]);
  

  useEffect(() => {
    getUsers();
  }, []);
    // Make an API request to the PHP server
    function getUsers() {
    axios.get("https://yesedacademy.com/database/testimonial.php", {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then((response) => {
      //console.log(response.data);
      setUsers(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
 
  
  return (
   
    <section className="client_section layout_padding2" id="testimonils_section">
  <div className="container ">
    <div className="heading_container heading_center">
      <h2>
        Student Reviews
      </h2>
      <hr />
    </div>
    <fieldset>
      
    <div id="carouselExample2Controls" className="carousel slide text-center " data-mdb-ride="carousel">
      <div className="carousel-inner2">



<Carousel showControls indicators={false}>

{users.map((user) => 
 
 

      <Carousel.Item>
        
      <div key={user.id} >
    <img className="rounded-circle shadow-1-strong mb-4" src={user.image !== '' ? `https://yesedacademy.com/database/${user.image}` : `https://yesedacademy.com/database/images/user.png`} alt="avatar" style={{width: 150}} />
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">

        <h5 className="mb-3">{user.name}</h5>
        <p>{user.profession}{user.workplace !== ` ` && `, ${user.workplace}`}</p> 

      
          


        
        <p className="text-muted">
          <span> <i className="fa fa-quote-left pe-2" /></span>
          {user.review}
          <span> <i className="fa fa-quote-right pe-2" /></span></p>
      </div>
    </div>
    
</div>
        </Carousel.Item>

)}
      
    </Carousel>

 

        
        
      </div>

      {/*
      <div className="carousel_btn-box">
        <a className="carousel-control-prev" href="#carouselExample2Controls" role="button" data-slide="prev">
          <span>
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExample2Controls" role="button" data-slide="next">
          <span>
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </span>
          <span className="sr-only">Next</span>
        </a>
      </div>
*/}



    </div>
    </fieldset>
  </div>
</section>


  );
  

}

export default Testimonials;
