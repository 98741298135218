import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';


import { BrowserRouter as  Router,Route, Routes} from "react-router-dom";
import {
  Header,
  Footer,
  Rights,
} from "./components";
import Home from "./Pages/Home";
import AboutSection from './Pages/AboutSection';
import ServiceSection from './Pages/serviceSection';
import TestimonialSection from './Pages/TestimonialSection';
import ContactSection from './Pages/Contact';
//import LoginSection from 'https://login.yesedacademy.com';
//import UploadTestimonial from './Pages/uploadTestimonial';
import "./css/MainStyle.css";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
  <React.StrictMode>
 
  <Router>
    <Header/>

   <Routes>
   
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<AboutSection />} />
    <Route path="/services" element={<ServiceSection />} />
    <Route path="/testimonials" element={<TestimonialSection />} />
    <Route path="/contact" element={<ContactSection />} />
    <Route path="/login" />
    
    

   </Routes>
   <Footer/>
   <Rights />
  </Router>
  </React.StrictMode>
);

document.getElementById("root")

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();