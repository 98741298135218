import React, { } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/logowebWhite.png";
//import "../css/MainStyle.css";

class Footer extends React.Component{
  openWhatsApp = () => {
    const phoneNumber = '918848288848'; // Replace this with the desired phone number
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };
  render() {
  return (
    <section className="info_section" id="contact_section">
  <div className="container">
    <div className="info_top">
      <div className="row">
        <div className="col-md-3 ">
          <NavLink className="navbar-brand" to="/">
            <img width="200px" alt="logo" src={Logo} />
          </NavLink>
        </div>
        <div className="col-md-5 ">
          <div className="info_contact">
            {/*<a href="">
         <i class="fa fa-map-marker" aria-hidden="true"></i>
          <span>
            
          </span>
        </a>
        <a href="">
			<i class="fa fa-phone" aria-hidden="true"></i>
          <span>
         
          </span>
        </a>*/}	
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="social_box"> 
          <NavLink className="nav-link" onClick={this.openWhatsApp}>
              <i className="fa fa-whatsapp" aria-hidden="true" />
            </NavLink>
            <NavLink className="nav-link" href>
              <i className="fa fa-facebook" aria-hidden="true" />
            </NavLink>
            {/*<NavLink className="nav-link" href>
              <i className="fa fa-twitter" aria-hidden="true" />
            </NavLink>
            <NavLink className="nav-link" href>
              <i className="fa fa-linkedin" aria-hidden="true" />
      </NavLink>*/}
            <NavLink target="_index" to="https://www.instagram.com/yesedacademy" className="nav-link">
              <i className="fa fa-instagram" aria-hidden="true" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
    <div className="info_bottom">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          {/*<div className="info_form">
            <h5>
              <i className="fa fa-newspaper-o" aria-hidden="true" /> &nbsp; NEWSLETTER
            </h5>
            <form action>
              <input type="text" placeholder="Enter Your Email" />
              <button type="submit">
                Subscribe
              </button>
      </form>
          </div>*/}
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="info_detail">
            <h5>
              <i className="fa fa-address-book" aria-hidden="true" /> &nbsp; Contact
            </h5>
            <p>
            <i className="fa fa-phone" aria-hidden="true" /> &nbsp; +91 8848 28 8848
            </p>
            <p>
            <i className="fa fa-envelope" aria-hidden="true" /> &nbsp; info@yesedacademy.com
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="info_detail">
            <h5>
              <i className="fa fa-map-marker" aria-hidden="true" /> &nbsp; Location
            </h5>
            <p>
              1st Floor, Raj Arcade Building, <br />Parakode P O,<br />Adoor, Pathanathitta,<br />Kerala, India.<br />Pincode : 691554
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className>
            <h5>
              Useful links
            </h5>
            <ul className="info_menu">
              <li>
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/services">
                  Services
                </NavLink>
              </li>
              <li className="mb-0">
                <NavLink className="nav-link" to="/contact">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
}
}

export default Footer;
