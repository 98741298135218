import React from "react";

import '../css/testimonial.css';
import { useEffect, useState } from "react";
import axios from 'axios';
import Posts from "../components/Posts";
//import Pagination from "../components/Pagination";
import ReactPaginate from 'react-paginate';


function TestimonialSection() {

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get('https://yesedacademy.com/database/testimonial.php');
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  //const paginate = pageNumber => setCurrentPage(pageNumber);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
 };

 /* const previousPage = () => {
    if (currentPage !== 1) {
       setCurrentPage(currentPage - 1);
    }
 };

 const nextPage = () => {
    if (currentPage !== Math.ceil(posts.length / postsPerPage)) {
       setCurrentPage(currentPage + 1);
    }
 };*/

  return (
    <div className="header">
        <h2>
        Student Reviews
      </h2>
      <Posts posts={currentPosts} loading={loading} />
      <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(posts.length / postsPerPage)}
                  previousLabel={'Prev'}
                  nextLabel={'Next'}
                  containerClassName={'pagination'}
                  pageLinkClassName={'page-number'}
                  previousLinkClassName={'page-number'}
                  nextLinkClassName={'page-number'}
                  activeLinkClassName={'active'}
  />
  
  

    </div>
  );
};


export default TestimonialSection;