import React, { } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/logoweb.png";

class Rights extends React.Component{
  render() {
  return (
    <footer className="footer_section">
  <div className="container">
    <p>
      © <span id="displayYear" /> All Rights Reserved By
      <NavLink to="/"><img alt="logo" width="80px" src={Logo} /></NavLink>
    </p>
  </div>
</footer>

    

  );
}
}

export default Rights;
