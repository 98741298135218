import React, { } from "react";
import useScrollDirection from "./useScrollDirection";
import { NavLink } from "react-router-dom";
import Logo from "../images/logowebWhite.png";

function Header() {
  const scrollDirection = useScrollDirection();

  /*const handleLoginClick = () => {
    window.location.href = 'https://login.yesedacademy.com';
  };*/
  return (
    <div className={`headerSticky ${ scrollDirection === "down" ? "hide" : "show"}`}>
     <header className="header_section">
  <div className="header_top">
    <div className="container-fluid ">
      <nav className="navbar navbar-expand-lg custom_nav-container ">
        <NavLink className="navbar-brand " to="/"> <img width="200px" alt="logo" src={Logo} /> </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className> </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav  ">
            <li className="nav-item">
            <NavLink className="contact_nav2 nav-link" to="/contact"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;+91 8848 28 8848</NavLink>
            </li>
            <li className="nav-item active">
              <NavLink className="nav-link" to="/"> Home <span className="sr-only">(current)</span> </NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/about"> About</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/services">Services</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/testimonials">Reviews</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/contact">Contact Us</NavLink>
            </li>
           {/*<li className="nav-item">
            <NavLink className="nav-link" to="/login" onClick={handleLoginClick}><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Login</NavLink>
  </li>*/}
            {/*<li className="nav-item">
            <NavLink className="nav-link" to="/login"><i className="fa fa-user" aria-hidden="true"></i>Login</NavLink>
            </li>*/}
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
</div>

    
  );
}

export default Header;
