import React from 'react';
import {Table, Tbody, Tr, Td} from 'react-super-responsive-table';
import ReactLoading from "react-loading";
import '../css/testimonial.css';

const Posts = ({ posts, loading }) => {
  if (loading) {
    return <div className='loading'> <ReactLoading type="spinningBubbles" color="#56148a"
    height={100} width={100} /></div>;
  }

  return (
   
     
      <Table className="responsiveTable">
      
      <Tbody>
      {posts.map(post => 
        <Tr key={post.id}>
          <Td style={{ width: '100px' }}><img className="rounded-corners" src={post.image !== '' ? `https://yesedacademy.com/database/${post.image}` : `https://yesedacademy.com/database/images/user.png`} alt="avatar" style={{width: 50}} /></Td>
          <Td ><p className="name">{post.name}</p><p className="designation">{post.profession}</p><p className="designation"> {post.workplace !== ` ` && `${post.workplace}`}</p></Td>
          <Td className="testimonial"><i className="fa fa-quote-left pe-2" />{post.review}  <i className="fa fa-quote-right pe-2" /></Td>
        </Tr>
        )}
        
      </Tbody>
    </Table>
    
   
    
  );
};

export default Posts;