import React from "react";

class ServiceSection extends React.Component {
    render() {
        return (
            <section className="about_section layout_padding2" id="service_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-13 offset-md-1">
                            <div className="detail-box pr-md-2">
                                <div className="heading_container">
                                    <h2>
                                        Our OET Preparation Services
                                    </h2>
                                </div>

                                <p className="detail_p_mt">
                                    At YesEd Academy, we are dedicated to providing top-tier OET preparation services designed to meet the unique needs of healthcare professionals aiming to excel in the Occupational English Test. Our comprehensive range of services is tailored to enhance your language proficiency, healthcare-specific communication skills, and overall OET performance.
                                </p><br />
                                <h3>OET Preparation Courses</h3>

                                <p>
                                    Our specialized OET Preparation Courses are meticulously designed to equip you with the skills needed to succeed in all four language domains: Listening, Reading, Writing, and Speaking. Our curriculum is structured around authentic healthcare scenarios to ensure your readiness for real-world interactions
                                </p>
                                <br />

                                <ul>

                                    <li>
                                        <span>Expert Instructors</span><br />Learn from experienced language trainers and healthcare experts who understand the intricacies of the OET. Our instructors bring their industry insights and instructional expertise to guide you throughout your preparation journey

                                    </li>

                                    <li>
                                        <span>Personalized Learning Paths</span><br /> We recognize that each student has unique strengths and areas for improvement. Our diagnostic assessments help us tailor a personalized learning path that maximizes your progress, ensuring you focus on areas that matter most
                                    </li>

                                    <li>
                                        <span>Realistic Practice Simulations</span><br /> Practice makes perfect, especially when it comes to OET success. Our realistic practice simulations mirror the actual healthcare environment, enabling you to confidently navigate patient consultations, role-plays, and communication tasks
                                    </li>

                                    <li>
                                        <span>Comprehensive Study Materials</span><br /> Access a rich repository of OET-specific resources, including practice tests, vocabulary guides, sample materials, and more. These materials are designed to enhance your comprehension of medical terminology and healthcare communication
                                    </li>

                                    <li>
                                        <span>Mock Tests and Performance Analysis</span><br /> Measure your readiness with our mock OET tests that closely resemble the actual exam conditions. Receive detailed performance analysis and feedback, allowing you to fine-tune your skills and focus on areas that require improvement
                                    </li>
                                    <li>
                                        <span>Continuous Support</span><br /> Our commitment to your success extends beyond the classroom. Benefit from ongoing support, doubt-clearing sessions, and expert guidance as you progress through your OET journey
                                    </li>
                                    <li>
                                        <span>Success Stories</span><br /> Discover the inspiring success stories of healthcare professionals who have achieved their OET goals with YesEd Academy. These stories exemplify how our services have transformed careers and opened doors to new opportunities
                                    </li>

                                </ul>

                            </div>
                        </div>
                        <div className="col-md-14 px-0">
                            <div className="img-box ">
                                <img src="images/about-img.jpg" className="box_img" alt="about img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;