import React, { } from "react";
import Slider from "../components/Slider";
import Services from "../components/Services";
import About from "../components/About";
import Testimonials from "../components/Testimonials";


class Home extends React.Component {
    render() {
        return (
            <div>
                <Slider />
                <Services />
                <About />
                
                <Testimonials />
               
            </div>
        );
    }
}
export default Home;
