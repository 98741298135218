import React from "react";


class AboutSection extends React.Component {
    render() {
        return (
            <section className="about_section layout_padding2" id="about_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-13 offset-md-1">
                            <div className="detail-box pr-md-2">
                                <div className="heading_container">
                                    <h2>
                                        About Us
                                    </h2>
                                </div>
                                <div>
                                    <h3>
                                        Welcome to YesEd Academy - <span>Pursue Limitless Dreams</span>
                                    </h3>
                                </div>
                                <p className="detail_p_mt">
                                    At YesEd Academy, we are dedicated to empowering healthcare professionals like you to achieve excellence in the Occupational English Test (OET). Our institution was founded with a clear mission: to provide comprehensive, high-quality, and personalized training that equips you with the language skills and confidence needed to excel in the healthcare sector.
                                </p><br />
                                <h3>Our Story</h3>

                                <p>
                                    Our journey began with a team of passionate educators and language experts who recognized the unique challenges faced by healthcare professionals aiming to work or further their careers in English-speaking countries. We saw the need for a specialized institution that not only focuses on English language proficiency but also understands the context, terminology, and communication intricacies specific to the healthcare industry.
                                </p>
                                <br />
                                <h3> What Sets Us Apart</h3>
                                <ul>

                                    <li>
                                        <span>Healthcare-Centric Approach :</span> Unlike traditional language institutes, we specialize exclusively in preparing healthcare professionals for the OET. This focused approach allows us to tailor our curriculum, materials, and practice scenarios to the healthcare domain.
                                    </li>

                                    <li>
                                        <span>Experienced Faculty :</span> Our instructors are experienced language trainers and healthcare professionals. They bring their expertise and industry insights to the classroom, ensuring that you receive the most relevant and up-to-date training.
                                    </li>

                                    <li>
                                        <span>Customized Learning Paths :</span> We recognize that each individual's starting point and goals are unique. Our diagnostic assessments help us understand your strengths and areas for improvement, allowing us to create a personalized learning path that maximizes your progress.
                                    </li>

                                    <li>
                                        <span>Realistic Practice :</span> Success in the OET requires more than just theoretical knowledge. Our practice sessions mirror real-world healthcare scenarios, enabling you to confidently navigate patient interactions, consultations, and documentation tasks.
                                    </li>

                                    <li>
                                        <span>Comprehensive Resources :</span> Gain access to an extensive library of OET-specific resources, including practice tests, sample materials, vocabulary guides, and more. These resources are designed to reinforce your learning and provide you with the tools you need to excel.
                                    </li>

                                </ul>


                                <h3>Our Commitment</h3>

                                <p>
                                    We are committed to your success. Whether you're a nurse, doctor, dentist, or any other healthcare professional, we understand the importance of achieving a high OET score. Your success is not just a testament to your language proficiency, but also a reflection of the care and dedication you bring to your patients. That's why we are here to support you every step of the way.
                                </p>
                                <br />

                                <p>
                                    Thank you for considering <b>YesEd Academy</b> as your partner on this journey. Together, let's pave the way to your brighter future in the global healthcare landscape.
                                </p>

                            </div>
                        </div>
                        <div className="col-md-14 px-0">
                            <div className="img-box ">
                                <img src="images/about-img.jpg" className="box_img" alt="about img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default AboutSection;
