import React from "react";

class ContactSection extends React.Component {
    render() {
        return (
            <div></div>
        );
    }
}


export default ContactSection;